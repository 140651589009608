import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Paper, Stack, TextField, Button } from '@mui/material';
import 'assets/css/Login.css';
import Verification from 'components/common/Verification/Verification';
import ErrorAlert from 'components/common/ErrorAlert';
import AxiosRequest from 'api/AxiosRequest';

const ForgotPin = () => {
    const [activeStep, setActiveStep] = useState(1);
    const [newPin, setNewPin] = useState(null);
    const [confirmPin, setConfirmPin] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [email, setEmail] = useState(null);
    const [forgotPinProps, setForgotPinProps] = useState(null);
    const [legacyForgotPin, setLegacyForgotPin] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const ResetPassword = (e) => {
        e.preventDefault();
        
        if (newPin !== confirmPin) {
            return setErrorMessage('New security PIN confirmation does not match.');
        }

        const data = {
            "new_password": newPin,
            "verification_receipt": forgotPinProps.receipt
        };

        AxiosRequest("Accounts/password", data).then((response) => {
            alert("Your pin has been successfully reset!");
            window.location.href = "/login";
        })
        .catch(error => {
            console.log(error);
            if (error && error.response) {
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            }
        });
    }
    
    const LegacyPinReminder = (e) => {
        e.preventDefault();

        const data = {
            "provider_type": "legacy_pin",
            "lookup": email
        };

        AxiosRequest("Access/Reminders", data).then(() => {
            alert("Your reminder has been sent. Please check your email.");
            window.location.href = "/login";
        })
        .catch(error => {
            console.log(error);
            if (error && error.response) {
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            }
        });
    }

    //detect if legacy forgot pin URL
    useEffect(() => {
        if (searchParams.get("type") === "legacy_pin") {
            setLegacyForgotPin(true);
        } else {
            setLegacyForgotPin(false);
        }
    }, [searchParams.get("type")]);

    return (
        <div id='forgot-pin' className='login-signup'>
            {!legacyForgotPin &&
            <Paper elevation={12}>
                {activeStep && activeStep === 1 &&
                <h2>It's okay. It happens!</h2>
                }
                <Verification scope={"accounts.password.update"} getActiveStep={setActiveStep} 
                    getProps={setForgotPinProps}
                    comp={
                        <section className={"callback-section step-section"}>
                            <h2>Set your PIN</h2>
                            <p>Choose a 4-digit PIN you will use to log in.</p>
                            <form onSubmit={ResetPassword}>
                                <Stack direction="column" spacing={ 2 }>
                                    <TextField label="Enter a 4-digit PIN"  variant="outlined" size="medium" type='password'
                                        onChange={e => setNewPin(e.target.value)} 
                                    />
                                    <TextField label="Confirm your 4-digit PIN"  variant="outlined" size="medium" type='password'
                                        onChange={e => setConfirmPin(e.target.value)} 
                                    />
                                    {errorMessage &&
                                    <ErrorAlert message={errorMessage} code={errorCode} />
                                    }
                                    <Button variant="contained" size="large" type="submit" className='login-submit'>
                                        Reset PIN
                                    </Button>
                                </Stack>
                            </form>
                        </section>
                    }
                />
                <div className='login-options'>
                    <p>Want to try logging in again? 
                        <NavLink to='/login' id="forgot-pin-login" className="signup-login-switch">Log In Here</NavLink>
                    </p>
                </div>
            </Paper>
            }
            {legacyForgotPin &&
            <Paper elevation={12}>
                <h2>It's okay. It happens!</h2>
                <form onSubmit={LegacyPinReminder}>
                    <Stack direction="column" spacing={ 2 }>
                        <TextField label="Your Email Address"  variant="outlined" size="medium" type='text'
                            onChange={e => setEmail(e.target.value)} required
                        />
                        {errorMessage &&
                        <ErrorAlert message={errorMessage} code={errorCode} />
                        }
                        <Button variant="contained" size="large" type="submit" className='login-submit'>
                            Get Reminder
                        </Button>
                    </Stack>
                </form>
                <div className='login-options'>
                    <p>Want to try logging in again? 
                        <NavLink to='/login?type=legacy_pin' id="forgot-legacy-pin-login" className="signup-login-switch">Log In Here</NavLink>
                    </p>
                </div>
            </Paper>
            }
        </div>
    )
}

export default ForgotPin;