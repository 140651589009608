import React, { useState } from 'react';
import { Button, Stack, TextField, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import ErrorAlert from 'components/common/ErrorAlert';
import AxiosRequest from 'api/AxiosRequest';

const VerificationRequest = ({nextStep, getVerificationData, scope}) => {
    const [phoneNumber, setPhoneNumber] = useState("")
    const [userEmail, setUserEmail] = useState("");
    const [method, setMethod] = useState("text");
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    const largeSubmitButtonScopes = ["accounts.create", "accounts.password.update"];

    const HandleRequestSubmit = (e) => {
        e.preventDefault();
        
        const data = {
            "phone_number": phoneNumber,
            "method": method,
            "scope": scope
        }

        AxiosRequest("verification/request/", data).then((response) => {
            if (response.data.verification_sid) {
                data.verification_sid = response.data.verification_sid;
                data.email_address = userEmail;
                getVerificationData(data);
                nextStep(2);
            }
            setErrorMessage(null);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status && error.response.status >= 500) {
                setErrorMessage("There was an error. Please try again.");    
            } else if (error.response.data.error) {
                setErrorMessage(error.response.data.error.message);
                setErrorCode(error.response.data.error.code);
            } else {
                setErrorMessage("There was an error. Please try again.");
            }
        });
    }

    return (
        <section className={"request-step step-section"}>
            <form action='' id="loginForm" onSubmit={HandleRequestSubmit}>
                <Stack direction="column" spacing={ 2 }>
                    
                    {scope === "accounts.create" &&
                    <TextField 
                        label="Enter your email" 
                        variant="outlined"
                        size="medium"
                        value={userEmail}
                        type="email"
                        onChange={e => setUserEmail(e.target.value)} 
                    />
                    }
                    <PhoneInput placeholder="Enter your phone number" value={phoneNumber}
                        onChange={setPhoneNumber} defaultCountry={"US"} withCountryCallingCode
                        countries={["US", "CA", "MX"]} addInternationalOption={false}
                        autoComplete='phone' name='phone' />
                    <RadioGroup className='verification-method-radio-group'
                        defaultValue="text"
                        name="radio-buttons-group"
                        onChange={e => setMethod(e.target.value)}
                    >
                        <FormControlLabel value="text" control={<Radio />} label="Text me with the verification code." />
                        <FormControlLabel value="call" control={<Radio />} label="Call me with the verification code." />
                    </RadioGroup>
                    {errorMessage &&
                    <ErrorAlert message={errorMessage} code={errorCode} />
                    }
                    {largeSubmitButtonScopes.includes(scope) &&
                    <Button variant="contained" size="large" type="submit" className='login-submit'>
                        Next
                    </Button>
                    }
                    {!largeSubmitButtonScopes.includes(scope) &&
                    <div className='verification-button-group'>
                        <Button variant='contained' type="submit">Next</Button>
                    </div>
                    }
                </Stack>
            </form>
        </section>
    )
}

export default VerificationRequest;