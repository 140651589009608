import axios from 'axios';
import { LEGACY_API_URL, RPC_API_URL } from "./Urls";
import Cookies from 'js-cookie';

const AxiosRequest = (endpoint, data, requestMethod ) => {
    if (!requestMethod) {
        requestMethod = 'POST'
    }

    let credentials = btoa(process.env.REACT_APP_SERVICE_ID + ":" + process.env.REACT_APP_APP_ID);

    const axiosHeaders = {
        'Authorization': "Basic " + credentials
    }

    if (Cookies.get("access_token")) {
        axiosHeaders["SPOOFINGAPI-ACCESS-TOKEN"] = Cookies.get("access_token");
    }

    // console.log(Cookies.get("access_token"));

    if (endpoint === "rpc") {
        if (Cookies.get("access_token")) {
            axiosHeaders["Authorization"] = "Bearer " + Cookies.get("access_token");
        }

        // assign data object to params var, remove id and method properties for use in params key of request
        const { method, id, ...params } = data;

        return axios({
            method: requestMethod,
            url: RPC_API_URL + "rpc",
            headers: axiosHeaders,
            data: {
                "jsonrpc": "2.0",
                "method": data.method,
                "id": data.id ? data.id : "",
                "params": params
            }
        })
    }

    return axios({
        method: requestMethod,
        url: LEGACY_API_URL + endpoint,
        headers: axiosHeaders,
        data: data
    })
};

export default AxiosRequest;