import { Alert, AlertTitle } from '@mui/material';
import Cookies from 'js-cookie';

const FirebaseAlerts = ({banner, store}) => {

    const closeBannerAlert = () => {
       const bannerAlert = document.getElementById("banner_alert");
       bannerAlert.style.display = "none";
    }
    
    const closeSaleAlert = () => {
        const bannerAlert = document.getElementById("sale_alert");
        bannerAlert.style.display = "none";
        Cookies.set("sale_alert", "hide", {
            expires: 7,
        });
    }

    return (
        <>
        {banner && banner.banner_alert_active && (banner.banner_alert_title || banner.banner_alert_body) &&
            <Alert severity="warning" id='banner_alert' className='site-alert' onClose={closeBannerAlert}>
                <AlertTitle>{banner.banner_alert_title}</AlertTitle>
                <p>{banner.banner_alert_body}</p>
            </Alert>
        }
        {store && store.sale_active && (store.sale_title || store.sale_body) && !Cookies.get("sale_alert") &&
            <Alert severity="info" id='sale_alert' className='site-alert' onClose={closeSaleAlert}>
                <AlertTitle>{store.sale_title}</AlertTitle>
                <p>{store.sale_body}</p>
            </Alert>
        }
        </>
        
    )
}

export default FirebaseAlerts;