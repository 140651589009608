import React, { useState, useEffect, useContext } from 'react';
import { DialogContent, Switch, FormControl, Button, Select, InputLabel, MenuItem, TextField, Grid } from '@mui/material';
import AxiosRequest from 'api/AxiosRequest';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Adjust from '@adjustcom/adjust-web-sdk';
import { Loader } from 'components/common/Loader';
import PaypalLogoColor from 'assets/images/purchase/paypal-logo-color.svg';
import PaypalLogoWhite from 'assets/images/purchase/paypal-logo-white.svg';
import CardRedirect from 'assets/images/purchase/card redirect.svg';
import ErrorAlert from 'components/common/ErrorAlert';
import { FirebaseContext } from 'api/Context';

const CompletePurchase = ({ userData, updateUserData, selectedPackage, autoReplenishList }) => {
    const {firebaseConfig} = useContext(FirebaseContext);
    const [clientSecret, setClientSecret] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const [publishableKey, setPublishableKey] = useState(null);
    const [isComplete, setIsComplete] = useState(false);
    const [autoReplenishChecked, setAutoReplenishChecked] = useState(false);
    const [autoReplenishPackage, setAutoReplenishPackage] = useState(selectedPackage.id);
    const [activeAutoReplenisher, setActiveAutoReplenisher] = useState(null);
    const [autoReplenishChanged, setAutoReplenishChanged] = useState(false);
    const [autoReplenishSaved, setAutoReplenishSaved] = useState(false);
    const [payPalActive, setPayPalActive] = useState(false);
    const [couponErrorMessage, setCouponErrorMessage] = useState(null);
    const [couponErrorCode, setCouponErrorCode] = useState(null)
    const [coupon, setCoupon] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);

    useEffect(() => {
        const data = {
            'method': "create_checkout_session",
            'id': "CreateCheckoutSession",
            'stripe_product_id': selectedPackage.id
        };

        AxiosRequest("rpc", data).then((response) => {
            setClientSecret(response.data.result.client_secret);
            setPublishableKey(response.data.result.publishable_key);
        })
        .catch(error => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if (publishableKey) {
            setStripePromise(loadStripe(publishableKey));
        }
    }, [publishableKey]);

    const onSuccessfulPurchase = () => {
        setIsComplete(true)
        updateUserData();

        Adjust.trackEvent({
            eventToken: "lg95ge",
            revenue: selectedPackage.amount
        });
        
        window.gtag("event", "purchase", {
            // transaction_id: response.data.resources.transaction.sid,
            value: selectedPackage.amount,
            currency: "USD",
            // coupon: response.data.resources.transaction.coupon,
        });
    };

    const UpdateAutoReplenisher = () => {
        let activeAR = 0;
        if (autoReplenishChecked == true) {
            activeAR = 1;
        }
        const arData = {
            'stripe_product_id': selectedPackage.id,
            'balance_threshold': 2.50,
            'is_active': activeAR
        }

        let autoReplenishSid = "";
        if (activeAutoReplenisher) {
            autoReplenishSid = activeAutoReplenisher;
        }

        AxiosRequest("Accounts/AutoReplenishers/" + autoReplenishSid, arData).then((response) => {
            setAutoReplenishChanged(false);
            setAutoReplenishSaved(true);
            setTimeout(() => {
                setAutoReplenishSaved(false);
            }, 5000);
        })
        .catch(error => {
            console.log(error);
        });
    }

    const handleAutoReplenishChange = (event) => {
        setAutoReplenishPackage(event.target.value);
        setAutoReplenishChanged(true);
        setAutoReplenishSaved(false);
    };

    const handleAutoReplenishCheck = () => {
        setAutoReplenishChecked(prevAutoReplenishChecked => !prevAutoReplenishChecked);
        setAutoReplenishChanged(true);
        setAutoReplenishSaved(false);
    };

    // check if user has auto replenisher enabled
    useEffect(() => {
        if (userData.plugins.auto_replenishers) {
            if (userData.plugins.auto_replenishers.filter(e => e.is_active).length > 0) {
                setActiveAutoReplenisher(userData.plugins.auto_replenishers.filter(e => e.is_active)[0].sid);
                setAutoReplenishChecked(true);
            } else {
                setAutoReplenishChecked(false);
            }
        }
    }, []);

    const submitPaypalPayment = () => {
        const data = {
            "coupon_code": coupon,
            "payment_method": "paypal-express",
            "return_url": window.location.href,
            "cancel_url": window.location.href,
            // 'purchase_amount_sid': selectedPackage.id,
            'stripe_product_id': selectedPackage.id,
        };

        console.log(data);
        AxiosRequest("Transactions", data).then((response) => {
            console.log(response);
            window.open(response.data.resources.transaction.gateways[0].redirect_url, '_blank');
            // closeDialog();
        })
        .catch(error => {
            console.log(error);
            if (error.response.status && error.response.status >= 500) {
                setErrorMessage("There was an error. Please try again.");
                setErrorCode(error.response.data.error.code);        
            } else if (error.response.data.error) {
                setErrorMessage(error.response.data.error.message);
                setErrorCode(error.response.data.error.code);
            } else {
                setErrorMessage("There was an error. Please try again.");
            }
        });
    }

    const SubmitCoupon = () => {
        const data = {
            "purchase_amount_sid": selectedPackage.sid,
            "coupon_code": coupon,
            "page": 1,
            "page_size": 1,
        }

        AxiosRequest("Coupons?purchase_amount_sid=" + data.purchase_amount_sid + "&coupon_code=" + data.coupon_code + 
        "&page="+ data.page +"&page_size=" + data.page, {}, "GET").then((response) => {
            ApplyCoupon(response.data.resources.coupons[0]);
            setCouponErrorMessage(null);
            setCouponErrorCode(null);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status && error.response.status >= 500) {
                setCouponErrorMessage("There was an error. Please try again.");
                setCouponErrorCode(error.response.data.error.code);        
            } else if (error.response.data.error) {
                setCouponErrorMessage(error.response.data.error.message);
                setCouponErrorCode(error.response.data.error.code);
            } else {
                setCouponErrorMessage("There was an error. Please try again.");
            }
        });
    }

    const ApplyCoupon = (coupon_object) => {
        var current_price = selectedPackage.amount;
        var discount = 0.00;
        var discounted_price = current_price;

        if (coupon_object.percentage_discount) {
            discount = current_price * coupon_object.percentage_discount / 100;
        } else if (coupon_object.value_discount) {
            discount = coupon_object.value_discount;
        }

        discount = discount.toFixed(2);
        discounted_price = Math.round((current_price - discount) * 100) / 100;

        document.getElementById('total-credits-summary').textContent = selectedPackage.credits + " Credits";
        document.getElementById('price-summary').textContent = "$" + discounted_price.toFixed(2);
        const couponCodeInfo =  document.getElementById("coupon-code-info")
        couponCodeInfo.innerHTML = '<strong>' + coupon_object.coupon_code + '</strong> ' + "$" + discount + " Discount Applied";
        couponCodeInfo.style.display = "block";
    }

    return (
        <DialogContent id="CompletePurchase">
            {clientSecret && stripePromise && (
                <div id="checkout">
                    {!payPalActive &&
                    <>
                    <EmbeddedCheckoutProvider stripe={stripePromise} options={{clientSecret, onComplete: onSuccessfulPurchase}}>
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                    <div className='formDivider'>OR</div>                    
                    <Button variant='contained' type='button' id='pay-with-paypal' 
                        onClick={() => setPayPalActive(true)}>
                        Pay with
                        <img src={PaypalLogoColor} alt='paypal logo' /> 
                    </Button>
                    </>
                    }
                    {payPalActive &&
                    <>
                    <p className='paypal-text'><img src={CardRedirect} alt='' />
                        After submitting your order, you will be redirected to securely complete your purchase.</p>
                    {/* <Grid className='purchase-inputs' container spacing={1} direction={"row"} style={{alignItems: "center", marginBottom: "15px"}}>
                        <Grid item xs={12} sm={6}>
                            <TextField label="Coupon?" variant="outlined" size="medium" fullWidth
                                onChange={e => setCoupon(e.target.value)} value={coupon} />
                        </Grid>   
                        <Grid item xs={12} sm={6}>
                            <Button onClick={SubmitCoupon} variant='text' id='apply-coupon-button'>Apply Coupon</Button>
                        </Grid>
                        {couponErrorMessage &&
                            <Grid item xs={12} sm={12}>
                                <ErrorAlert message={couponErrorMessage} code={couponErrorCode} />
                            </Grid>
                        }
                    </Grid> */}
                    
                    <div id="purchase-summary">
                        <p id='total-price'>Total Price:</p>
                        {/* <p id="previous-credits-summary">{previousCredits}</p> */}
                        <span id="total-credits-summary">{selectedPackage.metadata.credits + " Credits"}</span>
                        <span id="price-summary">${selectedPackage.amount}</span>
                        <p id="coupon-code-info" style={{ display: "none" }}></p>
                    </div>
                    {errorMessage &&
                        <div style={{ marginBottom: "10px" }}>
                            <ErrorAlert message={errorMessage} code={errorCode} />
                        </div>
                    }
                    <div id='paypal-buttons'>
                        <Button type='button' variant='outlined' onClick={() => setPayPalActive(false)}>
                            {firebaseConfig.store.choose_payment_method_button}
                        </Button>
                        <Button className='continue-to-paypal' type='button' variant='contained' 
                            onClick={submitPaypalPayment}>
                            Continue to
                            <img src={PaypalLogoWhite} alt='paypal logo' /> 
                        </Button>
                    </div>
                    </>
                    }
                    {isComplete &&
                    <div id="purchase-auto-replenish">
                        {/* <Box id="auto-replenish" className="box-padding" sx={{ borderBottom: 1, borderColor: 'divider' }}> */}
                            <div style={{display: "flex"}}>
                                <h2 className='auto-replenish-heading'>Auto Replenish</h2>
                                <Switch className='auto-replenish-switch'
                                    checked={autoReplenishChecked}
                                    onChange={handleAutoReplenishCheck}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color="success" 
                                />
                                </div>
                            <p>Automatically add credits when I have fewer than 15 credits left.</p>
                            <div>
                                <p style={{ fontSize: "14px", fontWeight: "700", lineHeight: "12px"}}>
                                    Automatically add:
                                </p>
                                <FormControl fullWidth id="purchase-auto-replenish-select">
                                    <InputLabel id="auto-replenish-label">Package</InputLabel>
                                    <Select
                                        labelId="auto-replenish-label" 
                                        label="Package" 
                                        value={autoReplenishPackage}
                                        onChange={handleAutoReplenishChange}
                                        className="purchase-auto-replenish-select auto-replenish-select"
                                    >
                                        {autoReplenishList && 
                                            autoReplenishList.map((p, i) => {
                                                return (
                                                    <MenuItem key={i} value={p.id} >
                                                        {p.metadata.credits} Credits for ${p.amount}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                                <div className='update-auto-replenish-controls'>
                                    <Button type='submit' variant='outlined' onClick={UpdateAutoReplenisher}
                                        className='update-auto-replenish-button'>Update Settings</Button>
                                    {autoReplenishChanged &&
                                    <span className="update-auto-replenish-text">
                                        Click "Update Settings" to save changes.</span>
                                    }
                                    {autoReplenishSaved &&
                                    <span className="update-auto-replenish-saved">Saved!</span>
                                    }
                                </div>
                            </div>
                        {/* </Box> */}
                    </div>
                    }
                </div>
            )}
            {!clientSecret && !stripePromise &&
                <Loader />
            }
        </DialogContent>
    );
};

export default CompletePurchase;
