import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const ErrorAlert = ({ message, code }) => {
    return (
        <Alert severity="error" className='ErrorAlert'>
            <AlertTitle>Error {code === null ? '' : '('+code+')'}</AlertTitle>
            {message}
        </Alert>
    );
};

export default ErrorAlert;
