import { Link } from 'react-router-dom';
import 'assets/css/Footer.css';
import Logo from 'assets/images/logo/logo_blue_transparent.svg';

const Footer = () => {
    return (
        <footer role="contentinfo" className="site-footer">
            <div className='wrapper'>
                <div className="copyright">
                    <Link to="/" id="footer-logo">
                        <img src={Logo} alt="Logo" />
                    </Link>
                    <p className="footer-date">© {new Date().getFullYear()} SpoofCard.com</p>
                </div>
                <nav className="footer-nav">
                    <ul className="footer-nav-col">
                        <li className="footer-nav-title">Get Started</li>
                        <li><Link to="/" id="footer-account">Account</Link></li>
                        <li><Link to="/settings" id="footer-settings">Account Settings</Link></li>
                    </ul>
                    <ul className="footer-nav-col">
                        <li className="footer-nav-title">Features</li>
                        <li><Link to="https://www.spoofcard.com/features" id="footer-caller-id" target="_blank" rel="noreferrer">Change Your Caller ID</Link></li>
                        <li><Link to="https://www.spoofcard.com/texting" id="footer-texting" target="_blank" rel="noreferrer">Texting</Link></li>
                        <li><Link to="https://www.spoofcard.com/features#record-calls" id="footer-record" target="_blank" rel="noreferrer">Record Calls</Link></li>
                        <li><Link to="https://www.spoofcard.com/straight-to-voicemail" id="footer-stvm" target="_blank" rel="noreferrer">Straight To Voicemail</Link></li>
                        <li><Link to="https://www.spoofcard.com/features#control-voice" id="footer-disguise" target="_blank" rel="noreferrer">Disguise Your Voice</Link></li>
                    </ul>
                    <ul className="footer-nav-col">
                        <li className="footer-nav-title">Learn More</li>
                        <li><Link to="https://www.spoofcard.com/blog" id="footer-blog" target="_blank" rel="noreferrer">Blog</Link></li>
                        <li><Link to="https://www.spoofcard.com/help" id="footer-support" target="_blank" rel="noreferrer">Support</Link></li>
                    </ul>
                    <ul className="footer-nav-col">
                        <li className="footer-nav-title">About</li>
                        <li><Link to="https://www.spoofcard.com/legal" id="footer-legal" target="_blank" rel="noreferrer">Legal</Link></li>
                        <li><Link to="https://www.spoofcard.com/privacy" id="footer-privacy" target="_blank" rel="noreferrer">Privacy Policy</Link></li>
                        <li><Link to="https://www.spoofcard.com/terms" id="footer-terms" target="_blank" rel="noreferrer">Terms of Service</Link></li>
                    </ul>
                </nav>
            </div>
        </footer>
    )
}

export default Footer;