import { useState } from 'react';
import { Link } from 'react-router-dom';
import { IconButton, Button, Accordion, AccordionSummary, AccordionDetails, Dialog } from '@mui/material';
import Cookies from 'js-cookie';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import TextsmsIcon from '@mui/icons-material/Textsms';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';

const HeaderMobileMenu = ({userData, Logout}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [expanded, setExpanded] = useState('');

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const changeAccordion = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div className="header-mobile-menu">
            <IconButton id="hamburger-button" className={`${isOpen ? 'open' : ''}`} onClick={toggleMenu} >
                {isOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
            <Dialog open={isOpen} onClose={() => setIsOpen(false)} id='header-mobile-dialog'>
                <ul className="mobile-menu-items">
                    <div className="accordion-menus">
                        {Cookies.get("access_token") &&
                        <Accordion expanded={expanded === 'panel0'} onChange={changeAccordion('panel0')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel0a-content" id="panel0a-header">
                                <p>Navigate</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="sub-menu">
                                    <li><Link to={"/"} id="mobile-header-place-call"><LocalPhoneIcon />Place A Call</Link></li>
                                    <li><Link to={"/text"} id="mobile-header-send-text"><TextsmsIcon />Send A Text</Link></li>
                                    <li><Link to={"/call-history"} id="mobile-header-call-history"><MenuBookIcon />Call History</Link></li>
                                    <li><Link to={"/settings"} id="mobile-header-settings"><SettingsIcon />Settings</Link></li>
                                    {userData && !userData.parent_account_sid && userData.is_corporate == 1 && 
                                        <li><Link to={"/manage-users"} id="mobile-header-manage-users"><GroupIcon />Manage Users</Link></li>
                                    }                
                                    {userData && !userData.parent_account_sid && userData.is_corporate == 0 && userData.is_fraud == 0 && 
                                        userData.can_spoof == 1 &&
                                        <li><Link to={"/upgrade-account"} id="mobile-header-upgrade"><ArrowCircleUpIcon />Upgrade</Link></li>
                                    }
                                    <li><Link onClick={Logout} id="mobile-header-log-out"><LogoutIcon />Log Out</Link></li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        }
                        <Accordion expanded={expanded === 'panel1'} onChange={changeAccordion('panel1')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                                <p>Learn More</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="sub-menu">
                                    <li><Link to="https://spoofcard.com/features" id="mobile-header-features" target="_blank" rel="noreferrer">Features</Link></li>
                                    <li><Link to="https://spoofcard.com/app" id="mobile-header-app" target="_blank" rel="noreferrer">Get Our App</Link></li>
                                    <li><Link to="https://spoofcard.com/blog" id="mobile-header-blog" target="_blank" rel="noreferrer">Blog</Link></li>
                                    <li><Link to="https://spoofcard.com/support" id="mobile-header-support" target="_blank" rel="noreferrer">Support</Link></li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={changeAccordion('panel2')}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                                <p>About</p>
                            </AccordionSummary>
                            <AccordionDetails>
                                <ul className="sub-menu">
                                    <li><Link to="https://spoofcard.com/legal" id="mobile-header-legal" target="_blank" rel="noreferrer">Legal</Link></li>
                                    <li><Link to="https://spoofcard.com/privacy-policy" id="mobile-header-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</Link></li>
                                    <li><Link to="https://spoofcard.com/terms" id="mobile-header-terms" target="_blank" rel="noreferrer">Terms of Service</Link></li>
                                </ul>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </ul>
            </Dialog>
        </div>
    );
};

export default HeaderMobileMenu;
