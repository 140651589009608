import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import { HideNavigation } from 'utils/HideNavigation';
import Purchase from 'components/views/Purchase';

const Navigation = ({unreadCount, GetUserData, updateUserData}) => {
    const [userData, setUserData] = useState(null);
    const [openPurchase, setOpenPurchase] = useState(false);
    const currentLocation = useLocation();

    useEffect(() => {
        GetUserData().then((response) => {
            return setUserData(response);
        })
    },[]);

    useEffect(() => {
        let unreadCountDiv = document.getElementById("unread-count");
		if (unreadCountDiv && unreadCount !== null && unreadCount > 0) {
            unreadCountDiv.innerText = unreadCount;
            unreadCountDiv = unreadCountDiv.style.display = "inline-block";
		}
	}, [unreadCount]);

    const handlePurchaseDialogClose = () => {
        setOpenPurchase(false);
    };
    
    const handlePurchaseButtonClick = (e) => {
        e.preventDefault();
        setOpenPurchase(true);
    };

    if (HideNavigation.includes(currentLocation.pathname)) {
        return null;
    } else {
        return (
            <div className="navigation">
                <>
                    <NavLink to={"/"} id='PlaceCall'>Place A Call</NavLink>
                    <NavLink to={"/text"} id='SendText'>Send A Text
                        {unreadCount && unreadCount > 0 && currentLocation.pathname !== "/text" &&
                        <div className="value-badge" id="unread-count" style={{ display: "none"}}></div>
                        }
                    </NavLink>
                    <NavLink to={"/call-history"} id='CallHistory'>Call History</NavLink>
                    <NavLink to={"/buy"} onClick={handlePurchaseButtonClick} id='AddCredits'>Add Credits</NavLink>
                    {userData && !userData.parent_account_sid && userData.is_corporate == 1 && 
                        <NavLink to={"/manage-users"} id='ManageUsers'>Manage Users</NavLink>
                    }                
                    {userData && !userData.parent_account_sid && userData.is_corporate == 0 && userData.is_fraud == 0 && 
                        userData.can_spoof == 1 &&
                        <Tooltip title="Upgrade to a Corporate Account for more features!">
                            <NavLink to={"/upgrade-account"} id='UpgradeAccountNav'>Upgrade Account</NavLink>
                        </Tooltip>
                    }
                    <NavLink to={"/settings"} id='Settings'>Settings</NavLink>
                </>
                {openPurchase &&
                    <Purchase open={openPurchase} closeDialog={handlePurchaseDialogClose} userData={userData} updateUserData={updateUserData} />
                }
            </div>
        );
    }
}

export default Navigation;
