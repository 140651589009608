import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

const SuccessAlert = ({ message }) => {
    return (
        <Alert severity="success" >
            <AlertTitle>Success!</AlertTitle>
            {message}
        </Alert>
    );
};

export default SuccessAlert;
