import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import HeaderMobileMenu from 'components/common/Header/HeaderMobileMenu';
import Cookies from 'js-cookie';

import 'assets/css/Header.css';
import Logo from 'assets/images/logo/logo_blue_transparent.svg';

const Header = ({userData, Logout}) => {
    return (
        <header>
            <div className='wrapper'>
                <div className="logo">
                    <Link to="/" id="header-logo">
                        <img src={Logo} alt="Logo" />
                    </Link>
                </div>
                <nav className="header-menu">
                    <ul>
                        <li><Link to="https://spoofcard.com/features" id="header-features" target="_blank" rel="noreferrer">Features</Link></li>
                        <li><Link to="https://tm9z9.app.goo.gl/web" id="header-app" target="_blank" rel="noreferrer">Get Our App</Link></li>
                        {Cookies.get("access_token") &&
                        <>
                        <li><Link onClick={Logout} id="header-log-out">Log Out</Link></li>
                        <li><Button className='dashboard-button' component={Link} to="/" variant="contained">Dashboard</Button></li>
                        </>
                        }
                        {!Cookies.get("access_token") &&
                        <li><Link to="/login">Log In</Link></li>
                        }
                    </ul>
                </nav>
            </div>
            <HeaderMobileMenu userData={userData} />
        </header>
    );
};

export default Header;
