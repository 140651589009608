import React, { useState, useEffect } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { Grid, Paper, Button, Stack, TextField } from '@mui/material';
import ErrorAlert from 'components/common/ErrorAlert';
import Cookies from 'js-cookie';
import PhoneInput from 'react-phone-number-input';
import AxiosRequest from 'api/AxiosRequest';
import axios from 'axios';
import { ButtonLoader } from 'components/common/Loader';
// import LoginBg from 'assets/images/background/login_bg.png';
import LoginBg from 'assets/images/background/login_bg.webp';
import 'assets/css/Login.css';
import 'react-phone-number-input/style.css';

const Login = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [pin, setPin] = useState("");
    const [ipAddress, setipAddress] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [legacyLogin, setLegacyLogin] = useState(false);
    const [loginLoading, setLoginLoading] = useState(false);

    useEffect(() => {
        axios.get('https://api.ipify.org?format=json').then((response) => {
            setipAddress(response.data.ip);
        })
        .catch(error => {
            console.log(error);
            setipAddress("");
        });
    });

    const HandleLoginSubmit = (e, type) => {
        e.preventDefault();
        setLoginLoading(true);

        let loginData = {};
        if (type && type === "legacy_pin") {
            loginData = {
                "provider_type": "legacy_pin",
                "pin": pin
            }
        } else {
            loginData = {
                "provider_type": "phone",
                "phone_number": phoneNumber,
                "passcode": pin,
                "ip_address": ipAddress
            }
        }

        AxiosRequest("Access/Tokens", loginData).then((response) => {            
            if (response.data.resources.token.access_token) {
                Cookies.set("access_token", response.data.resources.token.access_token, {
                    expires: 7,
                });

                let redirect = "/";
                window.location.href = redirect;
            }
        })
        .catch(error => {
            console.log(error);
            setLoginLoading(false);
            if (error && error.response) {
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            } else {
                setErrorMessage("There was an error. Please try again.");
            }
        });
    }

    //detect if legacy login URL
    useEffect(() => {
        if (searchParams.get("type") === "legacy_pin") {
            setLegacyLogin(true);
        } else {
            setLegacyLogin(false);
        }
    }, [searchParams.get("type")]);

    const handlePinChange = (e) => {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setPin(onlyNums);
    }

    return (
        <div className='login-signup'>
            <Grid container spacing={2} style={{textAlign: "left"}} direction={"row"}>
                <Grid item md={6} className="login-bg">
                    <div>
                        <h2>Protect your privacy with <br/><span>SpoofCard.</span></h2>
                        <img src={LoginBg} alt="" />
                    </div>
                </Grid>
                <Grid item md={6} sm={12} xs={12} id='login-paper'>
                    {!legacyLogin &&
                    <Paper elevation={12}>
                        <h2>Welcome back!</h2>
                        <form action='' id="loginForm" onSubmit={HandleLoginSubmit} >
                            <Stack direction="column" spacing={ 2 }>
                                <PhoneInput placeholder="Enter your phone number" value={phoneNumber}  
                                    onChange={setPhoneNumber} defaultCountry={"US"} withCountryCallingCode
                                    autoComplete='phone' name='phone' />
                                <TextField 
                                    label="Enter your 4-digit PIN" 
                                    variant="outlined"
                                    value={pin}
                                    type="password"
                                    onChange={e => handlePinChange(e)} 
                                    required
                                    inputProps={{ maxLength: 6 }}
                                />
                                <Button variant="contained" size="large" type="submit" className='login-submit'>
                                    {!loginLoading &&
                                    "Log In"
                                    }
                                    {loginLoading &&
                                    <ButtonLoader />
                                    }
                                </Button>
                                {errorMessage &&
                                <ErrorAlert message={errorMessage} code={errorCode} />
                                }
                                <div className='formDivider'>OR</div>
                                <div className='login-options'>
                                    <p>Have a Legacy PIN?
                                        <NavLink to='/login?type=legacy_pin' id="legacy-login" className="signup-login-switch">Log In Here</NavLink>
                                    </p>
                                    <p><NavLink to='/forgot-pin' id="forgot-pin" style={{padding: "0", marginBottom: "25px", textDecoration: "none"}}>
                                        Forgot your PIN?</NavLink>
                                    </p>
                                    <p>Don't have an account? 
                                        <NavLink to='/get-started' id="sign-up-button" className="signup-login-switch">Sign Up</NavLink>
                                    </p>
                                </div>
                            </Stack>
                        </form>
                    </Paper>
                    }
                    {legacyLogin &&
                    <Paper elevation={12}>
                        <h2>Log In with a Legacy PIN</h2>
                        <p>If you have a legacy PIN, you may log in here.</p>
                        <form action='' id="loginForm" onSubmit={(e) => HandleLoginSubmit(e, "legacy_pin")} >
                            <Stack direction="column" spacing={ 2 }>
                                <TextField label="Enter your Legacy PIN" variant="outlined" size="medium"
                                    value={pin} onChange={e => setPin(e.target.value)} required />
                                <Button variant="contained" size="large" type="submit" className='login-submit'>
                                    {!loginLoading &&
                                    "Log In"
                                    }
                                    {loginLoading &&
                                    <ButtonLoader />
                                    }
                                </Button>
                                {errorMessage &&
                                <ErrorAlert message={errorMessage} code={errorCode} />
                                }
                                <div className='login-options'>
                                    <p>Don't have a Legacy PIN?
                                        <NavLink to='/login' id="standard-login" className="signup-login-switch">Log In Here</NavLink>
                                    </p>
                                    <p><NavLink to='/forgot-pin?type=legacy_pin' id="legacy-forgot-pin" style={{padding: "0", marginBottom: "25px", textDecoration: "none"}}>
                                        Forgot your PIN?</NavLink>
                                    </p>
                                    <p>Don't have an account? 
                                        <NavLink to='/get-started' id="legacy-sign-up-button" className="signup-login-switch">Sign Up</NavLink>
                                    </p>
                                </div>
                            </Stack>
                        </form>
                    </Paper>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default Login;