const VerificationCallback = ({comp}) => {
    // This component is third step in any Verification process (if necessary)
    // comp prop is whatever component is being rendered in third step

    return (
        <section className={"request-step step-section"}>
            {comp}
        </section>
    )
}

export default VerificationCallback;