import React, { useState, useEffect } from 'react';
import VerificationRequest from 'components/common/Verification/VerificationRequest';
import VerificationVerify from 'components/common/Verification/VerificationVerify';
import VerificationCallback from 'components/common/Verification/VerificationCallback';

const Verification = ({scope, getActiveStep, callBack, comp, callBackSuccessMessage, callBackErrorMessage, callBackErrorCode, getProps}) => {
    const [activeStep, setActiveStep] = useState(1);
    const [verificationData, setVerificationData] = useState(null);
    const [receipt, setReceipt] = useState(null);

    useEffect(() => {
        getActiveStep(activeStep);
    }, [activeStep]);
    
    return (
        <div className='verification'>
            {activeStep === 1 &&
            <VerificationRequest nextStep={setActiveStep} getVerificationData={setVerificationData} scope={scope} />
            }
            {activeStep === 2 &&
            // callBack func is provided to VerificationVerify if only two steps or if function needs to be run
            // before step 3. See HandleVerifySubmit func in VerificationVerify for more info
            <VerificationVerify nextStep={setActiveStep} verificationData={verificationData} getReceipt={setReceipt} 
                callBack={callBack} comp={comp} callBackSuccessMessage={callBackSuccessMessage}
                callBackErrorMessage={callBackErrorMessage} callBackErrorCode={callBackErrorCode} getProps={getProps} />
            }
            {activeStep === 3 &&
            <VerificationCallback verificationData={verificationData} receipt={receipt} comp={comp} />
            }
        </div>
    );
};

export default Verification;
