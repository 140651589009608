import React, { useState, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import ErrorAlert from 'components/common/ErrorAlert';
import VerificationInput from "react-verification-input";
import AxiosRequest from 'api/AxiosRequest';
import SuccessAlert from 'components/common/SuccessAlert';

const VerificationVerify = ({nextStep, verificationData, getReceipt, callBack, comp, callBackSuccessMessage, callBackErrorMessage, callBackErrorCode, getProps}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [verificationCode, setVerificationCode] = useState(null);
    const [timerActive, setTimerActive] = useState(true);
    const [timer, setTimer] = useState(30);
    const [newVerificationSid, setNewVerificationSid] = useState(null);
    
    useEffect(() => {
        let interval = null;
        if (timerActive) {
            interval = setInterval(() => {
                setTimer((timer) => timer - 1);
            }, 1000);
        }
        if (timer === 0) {
            setTimerActive(false);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [timerActive, timer]);

    const HandleVerifySubmit = (e) => {
        e.preventDefault();
        
        let data = {
            "verification_sid": verificationData.verification_sid,
            "verification_code": verificationCode
        }

        if (newVerificationSid) {
            data.verification_sid = newVerificationSid;
        }
        
        AxiosRequest("verification/verify/", data).then((response) => {
            if (response.data.verification_receipt) {
                // if a callBack func has been provided to this component, we run it
                // if not, we advance to step 3, which renders VerificationCallBack component
                // if both provided (ex. accounts.phone_number.update when un-verified), we run both
                if (callBack && comp) {
                    callBack(response.data.verification_receipt);
                    getReceipt(response.data.verification_receipt);
                    nextStep(3);
                } else if (callBack && !comp) {
                    callBack(response.data.verification_receipt);
                } else {
                    getProps({'verificationData':verificationData, 'receipt':response.data.verification_receipt});
                    getReceipt(response.data.verification_receipt);
                    nextStep(3);
                }
            }
            setErrorMessage(null);
        })
        .catch(error => {
            console.log(error);
            if (error.response.status && error.response.status >= 500) {
                setErrorMessage("There was an error. Please try again.");
            } else if (error.response.data.error) {
                setErrorMessage(error.response.data.error.message);
                setErrorCode(error.response.data.error.code);
            } else {
                setErrorMessage("There was an error. Please try again.");
            }
        });
    }
    
    const ResendRequest = (method) => {
        const data = {
            "phone_number": verificationData.phone_number,
            "method": method,
            "scope": verificationData.scope
        }
        
        AxiosRequest("verification/request/", data).then((response) => {
            setNewVerificationSid(response.data.verification_sid);
            setErrorMessage(null);
            setTimerActive(true);
            setTimer(30);
        })
        .catch(error => {
            console.log(error);
            if (error && error.response) {
                if (error.response.status && error.response.status >= 500) {
                    setErrorMessage("There was an error. Please try again.");        
                } else if (error.response.data.error) {
                    setErrorMessage(error.response.data.error.message);
                    setErrorCode(error.response.data.error.code);
                } else {
                    setErrorMessage("There was an error. Please try again.");
                }
            }
        });
    }

    let resendInstead = "call";
    if (verificationData.method === "call") {
        resendInstead = "text";
    }

    return (
        <section className={"verify-step step-section"}>
            <h2>Enter the code</h2>
            <p>A verification code has been sent to {verificationData.phone_number}. Please enter the code you received to verify your number.</p>
            <form action='' id="loginForm" onSubmit={HandleVerifySubmit}>
                <Stack direction="column" spacing={ 2 }>
                    <div style={{textAlign: "center"}}>
                        <VerificationInput onChange={setVerificationCode} validChars='0-9' placeholder=''
                            classNames={{
                                container: "verification-container",
                                character: "verification-character",
                                characterInactive: "verification-character--inactive",
                                characterSelected: "verification-character--selected",
                                characterFilled: "verification-character--filled",
                            }}
                        />
                        <div className='resend-buttons'>
                            <Button onClick={() => ResendRequest(resendInstead)} 
                                disabled={timerActive} 
                                variant='text'>{resendInstead} me instead</Button>
                            <Button onClick={() => ResendRequest(verificationData.method)} 
                                disabled={timerActive} 
                                variant='text'>Resend code</Button>
                        </div>
                        {timer ? <div className='resend-timer'>{timer} seconds</div>
                        : <></>
                        }
                    </div>
                    {callBackSuccessMessage &&
                    <SuccessAlert message={callBackSuccessMessage} />
                    }
                    {callBackErrorMessage &&
                    <ErrorAlert message={callBackErrorMessage} code={callBackErrorCode} />
                    }
                    {errorMessage &&
                    <ErrorAlert message={errorMessage} code={errorCode} />
                    }
                    {verificationData.scope === "accounts.create" &&
                    <Button variant="contained" size="large" type="submit" className='login-submit'>
                        Verify
                    </Button>
                    }
                    {verificationData.scope !== "accounts.create" &&
                    <div className='verification-button-group'>
                        <Button variant='contained' type="submit">Verify</Button>
                    </div>
                    }
                </Stack>
            </form>
        </section>
    )
}

export default VerificationVerify;