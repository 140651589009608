// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, doc, getDoc, getDocs } from 'firebase/firestore/lite';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAkpfu1CG-aJBOI2ICl43p_J6MNljXdjVc",
    authDomain: "spoofcard-e6341.firebaseapp.com",
    databaseURL: "https://spoofcard-e6341.firebaseio.com",
    projectId: "spoofcard-e6341",
    storageBucket: "spoofcard-e6341.appspot.com",
    messagingSenderId: "758540402124",
    appId: "1:758540402124:web:8daf0fd3520310825b3169",
    measurementId: "G-VXQ0DWLKJF"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const fireStore = getFirestore(firebaseApp);

const FirebaseStringFunc = async () => {
    const querySnapshot = await getDocs(collection(fireStore, "Strings"));
	let strings = {};
    querySnapshot.forEach((doc) => {
		strings[doc.id] = doc.data();
    });

	return strings;
};

export const FirebaseStrings = FirebaseStringFunc().then(result => {
	return result;
});

const FirebaseConfigFunc = async () => {
    const hostname = window && window.location && window.location.hostname;
    let firebaseCollection = "config_web_app";
    firebaseCollection = hostname === "app.spoofcard.com" ? "config_web_app" : "config_web_app_qa";

    const querySnapshot = await getDocs(collection(fireStore, firebaseCollection));
	let config = {};
    querySnapshot.forEach((doc) => {
		config[doc.id] = doc.data();
    });

	return config;
};

export const FirebaseConfig = FirebaseConfigFunc().then(result => {
	return result;
});