import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Paper } from '@mui/material';

const PageNotFound = () => (
    <div id="page-not-found">
        <Paper elevation={12}>                        
            <h1>404</h1>
            <p>The page you are looking for can not be found</p>
            <Link to="/">
                <Button variant="contained">Go Home</Button>
            </Link>
        </Paper>
    </div>
);

export default PageNotFound;
