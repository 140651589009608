const hostname = window && window.location && window.location.hostname;

// array of host names that would use production api
const prodHostNames = ["app.spoofcard.com", "beta.spoofcard.com"];

let legacy_api_url = "";
let rpc_api_url = "";

if (prodHostNames.includes(hostname)) {
    legacy_api_url = 'https://api.spoofingapi.com/2011-07-15/';
    rpc_api_url = 'https://api.spoofingapi.com/';
} else {
    legacy_api_url = 'https://api-qa.spoofingapi.com/2011-07-15/';
    rpc_api_url = 'https://api-qa.spoofingapi.com/';
}

export const LEGACY_API_URL = legacy_api_url;
export const RPC_API_URL = rpc_api_url;
