import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Login from 'components/views/Login';
import SignUp from 'components/views/SignUp';
import ForgotPin from 'components/views/ForgotPin';
import Navigation from 'components/common/Navigation';
import MobileNavigation from 'components/common/MobileNavigation';
import Header from 'components/common/Header/Header';
import Footer from 'components/common/Footer';
import PrivateRoute from 'components/common/PrivateRoute';
import Cookies from 'js-cookie';
import { Loader } from 'components/common/Loader';
import { HideNavigation } from 'utils/HideNavigation';
import 'assets/css/styles.css';
import PageNotFound from 'components/views/PageNotFound';
import AxiosRequest from 'api/AxiosRequest';
import { LoggedInContext, FirebaseContext, PackagesContext } from 'api/Context';
import { FirebaseStrings, FirebaseConfig } from 'api/Firebase';
import FirebaseAlerts from 'components/common/FirebaseAlerts';
import AutoReplenishDialog from 'components/common/AutoReplenishDialog';

const Call = lazy(() => import('components/views/Call'));
const Text = lazy(() => import('components/views/Text'));
const CallHistory = lazy(() => import('components/views/CallHistory'));
const Settings = lazy(() => import('components/views/Settings'));
const Corporate = lazy(() => import('components/views/Corporate'));
const UpgradeAccount = lazy(() => import('components/views/UpgradeAccount'));

const App = () => {
	const currentLocation = useLocation();
	const [isLoggedIn, setIsLoggedIn] = useState(Cookies.get("access_token"));
	const [userData, setUserData] = useState({});
	const [unreadCount, setUnreadCount] = useState(null);
    const [creditCards, setCreditCards] = useState(null);
    const [autoReplenishDialogOpen, setAutoReplenishDialogOpen] = useState(false);
    const [autoReplenish, setAutoReplenish] = useState(null);
    const [autoReplenishQueried, setAutoReplenishQueried] = useState(false);
    const [packages, setPackages] = useState(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [firebaseStrings, setFirebaseStrings] = useState(null);
    const [firebaseConfig, setFirebaseConfig] = useState(null);
    const [autoReplenishPackages, setAutoReplenishPackages] = useState(null);

    useEffect(() => {
		//check that user is logged in, userData is loaded, and packages aren't loaded yet
        if (isLoggedIn && !packages && userData.sid) {
			ListPackages();
		}
    }, [userData]);

	const Logout = () => {
		Cookies.remove("access_token");
		setIsLoggedIn(null);
		return false;
	}

	const GetUserData = () => {
		if (Cookies.get("access_token")) {
			return AxiosRequest("Account", {}, "GET")
			.then((response) => {
				return response.data.resources.account;
			})
			.catch((error) => {
				console.log(error);
				Logout();
			});
		} else {
			Logout();
		}
	}

	// passing updateUserData func to Context Provider so we can re-render
	// user data when it is updated by user. we cannot pass userData state 
	// into useEffect below because it would cause infinite re-renders

	const ListPackages = () => {
        let group = "standard";
		if (firebaseConfig && firebaseConfig.store.package_group.length) {
			group = firebaseConfig.store.package_group;
		}
        // let prevCategory = null;
        if (userData.is_corporate) {
            group = "corporate";
        } else {
            // prevCategory = "five-packages";
        }
        
        const data = {
            'method': "list_products",
            'id': "ListProducts",
            'group': group,
        };

        AxiosRequest("rpc", data).then((response) => {
			let sortedPackages = response.data.result.sort((a,b) => (
				Number(a.metadata.credits) > Number(b.metadata.credits)) ? 
            	1 : ((Number(b.metadata.credits) > Number(a.metadata.credits)) ? -1 : 0)
			);
			sortedPackages.forEach(e => {
				if (e.default_price) {
					e.amount = (Math.round(e.default_price.unit_amount) / 100).toFixed(2);
				}
			});
            setPackages(sortedPackages);
			setSelectedPackage(sortedPackages[0].id);
        })
        .catch(error => {
            console.log(error);
        });
    };

    useEffect(() => {
		//check that user is logged in, userData is loaded, and packages aren't loaded yet
        if (isLoggedIn && !packages && userData.sid) {
			ListPackages();
		}
    }, [userData]);

	const CheckForNewTexts = () => {
        AxiosRequest('Accounts/' + userData.sid + '/Conversations?is_unread=true', {}, "GET").then((response) => {
            const conversations = response.data.resources.conversations;
            if (typeof conversations === 'undefined') {
				setUnreadCount(null);
                return;
            }

			setUnreadCount(conversations.length.toString());

            if (unreadCount >= 10) {
                setUnreadCount("10+");
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

	// this will run when Component is changed or updatedUserData is run
	useEffect(() => {
		updateUserData();
	}, [currentLocation]);

	const updateUserData = () => {
		if (isLoggedIn && !HideNavigation.includes(currentLocation.pathname)) {
			GetUserData().then((response) => {
				return setUserData(response);
			});

            CheckForNewTexts();
		} else {
			Logout();
		}
	}

    const ListCreditCards = () => {
        AxiosRequest("Accounts/" + userData.sid + "/CreditCards/?is_active=1", {}, "GET").then((response) => {
            setCreditCards(response.data.resources.credit_cards);
        })
        .catch(error => {
            console.log(error);
        });
    }

	const ListAccountAutoReplenishers = () => {
        AxiosRequest("Account/AutoReplenishers", {}, "GET").then((response) => {
            const accountAutoReplenishers = response.data.resources.auto_replenishers;
            accountAutoReplenishers.map((ar) => {
                if (ar.is_active) {
                    setAutoReplenish(ar);

					// need to do this so we know if auto replenish has been queried before showing the dialog
					setAutoReplenishQueried(true);
                }
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

	const handleAutoReplenishDialogClose = () => {
		setAutoReplenishDialogOpen(false);
    };

	const handleAutoReplenishChange = (event) => {
        setSelectedPackage(event.target.value);
    };

	useEffect(() => {
		FirebaseStrings.then(res => {
			setFirebaseStrings(res);
		});

		FirebaseConfig.then(res => {
			setFirebaseConfig(res);
		});

		if (isLoggedIn) {
			ListAccountAutoReplenishers();
		}
		if (!Cookies.get("AutoReplenishDialog")) {
			setAutoReplenishDialogOpen(true);
		} else {
			setAutoReplenishDialogOpen(false);
		}
	}, []);

	useEffect(() => {
		if (isLoggedIn) {
			ListCreditCards();
			// ListAutoReplenishPackages();
		}
	}, [userData]);

	return (
		<LoggedInContext.Provider value={{isLoggedIn, Logout}}>
		<PackagesContext.Provider value={{packages}}>
		<FirebaseContext.Provider value={{firebaseStrings, firebaseConfig}}>
		<div className="App">
			<Header userData={userData} Logout={Logout} />
			<div className="content">
				<div className="wrapper">
					{isLoggedIn &&
						<Navigation unreadCount={unreadCount} GetUserData={GetUserData} updateUserData={updateUserData} />
					}
					{isLoggedIn && firebaseConfig && (firebaseConfig.banner || firebaseConfig.store) &&
						<FirebaseAlerts banner={firebaseConfig.banner} store={firebaseConfig.store} />
					}
					{isLoggedIn 
					// && creditCards 
					&& !autoReplenish && autoReplenishQueried && selectedPackage &&
						<AutoReplenishDialog userData={userData} autoReplenishPackages={packages} selectedPackage={selectedPackage}
							handleAutoReplenishChange={handleAutoReplenishChange} open={autoReplenishDialogOpen} closeDialog={handleAutoReplenishDialogClose} />
					}
					<Routes>
						<Route path="/login" Component={Login} />
						<Route path="/get-started" Component={SignUp} />
						<Route path="/forgot-pin" Component={ForgotPin} />
						<Route path="/" element={<Suspense fallback={<Loader />}> 
							<PrivateRoute Component={Call} userData={userData} updateUserData={updateUserData} isLoggedIn={isLoggedIn} />
							</Suspense> } />
						<Route path="/text" element={<Suspense fallback={<Loader />}> 
							<PrivateRoute Component={Text} packages={packages} userData={userData} 
								updateUserData={updateUserData} isLoggedIn={isLoggedIn} />
							</Suspense> } />
						<Route path="/call-history" element={<Suspense fallback={<Loader />}> 
							<PrivateRoute Component={CallHistory} packages={packages} userData={userData} 
								updateUserData={updateUserData} isLoggedIn={isLoggedIn} />
							</Suspense> } />
						<Route path="/upgrade-account" element={<Suspense fallback={<Loader />}> 
							<PrivateRoute Component={UpgradeAccount} packages={packages} userData={userData} 
								updateUserData={updateUserData} isLoggedIn={isLoggedIn} />
							</Suspense> } />
						<Route path="/settings" element={<Suspense fallback={<Loader />}> 
							<PrivateRoute Component={Settings} packages={packages} 
								userData={userData} updateUserData={updateUserData} isLoggedIn={isLoggedIn} />
							</Suspense> } />
						<Route path="/manage-users" element={<Suspense fallback={<Loader />}> 
							<PrivateRoute Component={Corporate} packages={packages} 
								userData={userData} updateUserData={updateUserData} isLoggedIn={isLoggedIn} />
							</Suspense> } />
						<Route path="/*" Component={PageNotFound} />
					</Routes>
				</div>
			</div>
			<Footer />
			{isLoggedIn &&
				<MobileNavigation unreadCount={unreadCount} GetUserData={GetUserData} updateUserData={updateUserData} />
			}
		</div>
        </FirebaseContext.Provider>
		</PackagesContext.Provider>
        </LoggedInContext.Provider>
	);
}

export default App;
